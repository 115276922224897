import React from 'react'
import { useSpring, animated } from 'react-spring'

import Layout from '../components/layout'

const AboutChristian = () => (
  <div className="bg-gray-50 overflow-hidden border-t border-solid border-gray-200">
    <div className="relative max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-2 gap-2">
        <div className="col-span-2 sm:col-span-1">
          <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-600 sm:text-4xl sm:leading-10">
            Meet The Team
          </h2>
          <p className="text-gray-500">
            Our team is committed to the health and well being of kids and
            adults. Effort, hard work ethic, customer service, passion, fun and
            self improvement is our core values. This is something we teach kids
            and execute during any training session
          </p>
          <div className="flex items-baseline mt-4">
            <svg className="h-3.5 w-6 text-gray-300" fill="#008f99">
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
            <span className="text-gray-600">cortiz fitness</span>
          </div>
          <div className="flex items-baseline">
            <svg className="h-3.5 w-6 text-gray-300" fill="#ff7a38">
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
            <span className="text-gray-600">fit kidz 4 fun</span>
          </div>
        </div>
        <div className="col-span-2 sm:col-span-1">
          <div className="flex flex-col items-center">
            <span className="inline-block relative">
              <div className="bg-primary-500 p-1 rounded-full shadow-xl">
                <img
                  className="h-64 w-64 rounded-full"
                  src="https://res.cloudinary.com/leportail-app/image/upload/v1595885796/christian-headshot__g3icdx.png"
                  alt="coach Christian Ortiz"
                />
              </div>
            </span>
            <h1 className="mt-2 text-xl text-gray-600">Christian Ortiz</h1>
            <h1 className="mt-1 text-lg text-gray-600">
              CEO / Personal Trainer
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const AboutTeam = () => (
  <div className="bg-gray-50 overflow-hidden border-t border-solid border-gray-200">
    <div className="relative max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <svg
        className="absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4"
        width="404"
        height="784"
        fill="none"
        viewBox="0 0 404 784"
      >
        <defs>
          <pattern
            id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="4"
              height="4"
              className="text-gray-200"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width="404"
          height="784"
          fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)"
        />
      </svg>
      <div className="mt-4 lg:mt-0">
        <div className="relative grid grid-cols-2 sm:grid-cols-3 gap-2">
          <div className="flex flex-col items-center col-span-1">
            <div className="bg-primary-500 p-1 rounded-full shadow-xl">
              <img
                className="h-40 w-40 inline-block rounded-full"
                src="https://res.cloudinary.com/leportail-app/image/upload/v1595885935/Screenshot_20200726-185540_Gmail_hhlh3k.jpg"
                alt="personal trainer steve wilkos"
              />
            </div>
            <h1 className="mt-2 text-xl text-gray-600">Patience Santini</h1>
            <h1 className="mt-1 text-lg text-gray-600">
              Manager / Personal Trainer
            </h1>
          </div>
          <div className="flex flex-col items-center col-span-1">
            <div className="bg-accent-400 p-1 rounded-full shadow-xl">
              <img
                className="h-40 w-40 inline-block rounded-full"
                src="https://res.cloudinary.com/leportail-app/image/upload/v1595885695/IMG_2584_caijna.jpg"
                alt="personal trainer timeka wu"
              />
            </div>
            <h1 className="mt-2 text-xl text-gray-600">Rebecca Schaer</h1>
            <h1 className="mt-1 text-lg text-gray-600">
              Director of Kids Fitness
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
)
const MeetTheTeam = () => {
  const springOpacity = useSpring({
    from: { opacity: 0, transform: 'translateX(90px)' },
    to: { opacity: 1, transform: 'translateX(0)' },
  })
  return (
    <Layout seoTitle="Meet The Team">
      <animated.div style={springOpacity}>
        <AboutChristian />
        <AboutTeam />
      </animated.div>
    </Layout>
  )
}

export default MeetTheTeam
